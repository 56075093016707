// animates characters so they change color on mouse hover

export default function animateCharColors(lineClass, lineText, lineElementType, hoverColor) {
    // variables
    const lineElements = document.querySelectorAll('.' + lineClass);
    let lineCounter = 0;


    // functions
    lineElements.forEach(lineElement => {
        // ensure lineElement is empty, sometimes a glitch can happen where the text entered below will be there twice
        lineElement.innerHTML = '';

        // for the length of the associated lineElement text (lineText[0] for 1st lineElement, etc.)
        for (let i = 0; i < lineText[lineCounter].length; i++) {
            // append each letter of the associated lineText, as a child to the current lineElement
            lineElement.innerHTML += `<span class="animateCharColors${hoverColor} ${lineElementType[lineCounter]}">${lineText[lineCounter][i]}</span>`;
        }
        // increase lineElement counter, so the next foreach loop will use the next lineElement along in the array
        lineCounter++;
    });
}
