import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

// hooks
// import { useAuthContext } from './hooks/useAuthContext';

// components
import Footer from './components/Footer';

// pages
import Home from './pages/home/Home';

// styles
import styles from './App.module.css';

function App() {
  return (
    <div className={styles.app}>
      {(
        // BrowserRouter needs to wrap all code that utilises react-router
        <BrowserRouter>
          <div className={styles.pageWrapper}>
            <div className={styles.pageContentWrapper}>
              {/* Routes ensures only one route at a time is active, also Route must always be a child of Routes, Route can't be rendered on its own */}
              <Routes>
                {/* Route defines the url path that needs to be navigated to by the user, in order to load the defined component (or jsx code) */}
                <Route path="/" element={<Home />} />
                {/* catch-all route, will show the 404 page if no other paths above match with the url the user is navigating to */}
                <Route path="*" element={<Navigate to="/oops" />} />
              </Routes>
            </div>
            <Footer />
          </div>
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;
