// styles
import styles from './Footer.module.css';

export default function Footer() {
  return (
    <div className={`${styles.footer} pageSideMargins`}>
      <p >Revolve Digital Limited | Copyright © 2024</p>
    </div>
  );
}
