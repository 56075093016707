// animates an element to make it orbit around another element, from a bottom right to top left rotation
// orbitCenter and satellite must share the same parent element

// required css styling on elements:
// .parent of orbitCenter and satellite {
//     position: relative;
//     overflow: hidden;
// }
// .orbitCenter {
//     position: relative;
// }
// .satellite {
//     position: absolute;
//     opacity: 0;
//     transition: opacity 1s;
// }

import anime from 'animejs/lib/anime.es.js';

// uniqueClass = element class, satelliteHeight/satelliteWidth = px value, orbitSpeed/stepDelay = milliseconds, step = integer
// class passed into uniqueClass should only be on satellite and orbitCenter elements in DOM
// satelliteHeight/satelliteWidth need to be passed in as the satellite.offsetHeight/satellite.offsetWidth values aren't available to this script soon enough on page load
export default function animateOrbit(uniqueClass, satelliteHeight, satelliteWidth, orbitSpeed, step, stepDelay) {
    // define elements used in animation
    const orbitCenter = document.querySelector('.animateOrbitCenter' + uniqueClass);
    const satellite = document.querySelector('.animateOrbitSatellite' + uniqueClass);

    // calculate the initial top and right positions of the satellite element
    let satelliteInitialTop = orbitCenter.getBoundingClientRect().bottom;
    let satelliteInitialRight = orbitCenter.getBoundingClientRect().left - (satelliteWidth * 3);
    // the below two lines cause the moon to initially appear in the middle of the hero title text/orbitCenter ONLY after the code is built, when using npm start there's no issue, am keeping code here just in case it's needed in future
    // let satelliteInitialTop = orbitCenter.getBoundingClientRect().bottom - satelliteHeight;
    // let satelliteInitialRight = orbitCenter.getBoundingClientRect().left - satelliteWidth;

    // set empty "global" variable so animation.function() works
    let animation = anime();

    // set to true so a different animation runs once on page load to prevent a pathing bug
    let runOnce = true;

    // set starting positions/styling
    satellite.style.top = satelliteInitialTop + 'px';
    satellite.style.right = satelliteInitialRight + 'px';
    satellite.style.zIndex = 10;
    orbitCenter.style.zIndex = 5;

    // fade satellite in according to chosen step
    if (step === 1) {
        setTimeout(() => {
            satellite.style.opacity = 100;
        }, stepDelay);
    } else if (step === 2) {
        satellite.style.opacity = 100;
    }

    // configure animation (must happen after satellite has moved to initial position)
    const loopAnimation = () => {
        // stop old animation from playing simultaneously with new animation, mitigates a bug where the satellite path sometimes becomes warped when the animation is no longer on screen for too long; the warped path will only be used once before everything resets smoothly
        animation.remove(satellite);

        // configure animation, the initial animation that runs on page load is different to fix a bug where the satellite path is too low, which causes the satellite to not-nicely flash to the foreground in front of the orbitElement during the zIndex change
        if (runOnce) {
            animation = anime({
                targets: satellite,
                top: [
                    { value: orbitCenter.offsetTop, duration: 4000 },
                    { value: satelliteInitialTop, duration: 4000 },
                ],
                right: [
                    { value: orbitCenter.offsetLeft + orbitCenter.offsetWidth, duration: 4000 },
                    { value: satelliteInitialRight, duration: 4000 },
                ],
                scale: [
                    { value: 1.9, duration: 2000, easing: 'easeOutSine' },
                    { value: 1, duration: 2000, easing: 'easeInSine' },
                    { value: 0.1, duration: 2000, easing: 'easeOutSine' },
                    { value: 1, duration: 2000, easing: 'easeInSine' }
                ],
                zIndex: [
                    { value: 10, duration: 4000, easing: 'steps(1)' },
                    { value: 1, duration: 4000, easing: 'steps(1)' }
                ],
                loop: false,
                easing: 'easeInOutQuad',
                autoplay: false
            });

            // don't run this animation again
            runOnce = false;
        } else {
            animation = anime({
                targets: satellite,
                top: [
                    { value: orbitCenter.offsetTop, duration: 4000 },
                    { value: orbitCenter.offsetTop + (orbitCenter.offsetHeight - satellite.offsetHeight), duration: 4000 },
                ],
                right: [
                    { value: orbitCenter.offsetLeft + orbitCenter.offsetWidth, duration: 4000 },
                    { value: orbitCenter.offsetLeft - satellite.offsetWidth, duration: 4000 },
                ],
                scale: [
                    { value: 1.9, duration: 2000, easing: 'easeOutSine' },
                    { value: 1, duration: 2000, easing: 'easeInSine' },
                    { value: 0.1, duration: 2000, easing: 'easeOutSine' },
                    { value: 1, duration: 2000, easing: 'easeInSine' }
                ],
                zIndex: [
                    { value: 10, duration: 4000, easing: 'steps(1)' },
                    { value: 1, duration: 4000, easing: 'steps(1)' }
                ],
                loop: false,
                easing: 'easeInOutQuad',
                autoplay: false
            });
        }

        // play animation
        animation.play();
    }

//     const options = {
//         root: document.querySelector("#scrollArea"),
//         rootMargin: "0px",
//         threshold: 1.0,
//       };
      
//     const observer = new IntersectionObserver(callback, options);
    
//     const target = satellite;
// observer.observe(target);

    // start animation on continuous loop
    loopAnimation();
    setInterval(loopAnimation, orbitSpeed);
}
