// houses all functionality used in the hero section on the home page

// import functionality
import anime from 'animejs/lib/anime.es.js';
import animateOrbit from '../../scripts/animateOrbit';

// export monolithic function
export default function heroSection() {
    // sleep function used to make code wait at a certain line for a specified amount of milliseconds. functions using sleep() need to be async, use this function like so: await sleep(1000). sleep() will globally pause the code in it's tracks
    const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay))

    const fadeInHeroUpTargets = document.querySelectorAll('.fadeInHeroUp');
    const fadeInHeroDownTargets = document.querySelectorAll('.fadeInHeroDown');

    fadeInHeroUpTargets.forEach(target => {
        target.style.opacity = 0;
        target.style.transform = 'translateY(30px)';
    });
    fadeInHeroDownTargets.forEach(target => {
        target.style.opacity = 0;
        target.style.transform = 'translateY(-30px)';
    });

    const fadeInHeroTitles = async () => {
        // initialise animations
        let fadeInUp = (target) => {
            anime({
                targets: target,          // Target the selected element
                translateY: 0,      // Animate translateY from 50px below to 0 (its original position)
                opacity: 1,          // Animate opacity from 0 (hidden) to 1 (fully visible)
                duration: 500,           // Duration of the animation in milliseconds
                easing: 'linear',    // Easing function for a smooth effect
                autoplay: true            // Enable autoplay so starts immediately
            });
        }
        let fadeInDown = (target) => {
            anime({
                targets: target,          // Target the selected element
                translateY: 0,      // Animate translateY from 50px below to 0 (its original position)
                opacity: 1,          // Animate opacity from 0 (hidden) to 1 (fully visible)
                duration: 500,           // Duration of the animation in milliseconds
                easing: 'linear',    // Easing function for a smooth effect
                autoplay: true            // Enable autoplay so starts immediately
            });
        }

        // start animations in a staggered fashion
        fadeInUp(fadeInHeroUpTargets[0])
        await sleep(400);
        fadeInUp(fadeInHeroUpTargets[1])
        await sleep(1500);
        fadeInDown(fadeInHeroDownTargets[0])
        fadeInDown(fadeInHeroDownTargets[1])
    }

    // start fade in after loading screen has completed
    const fadeInHeroTitlesAsyncWrapper = async () => {
        await sleep(2000);
        fadeInHeroTitles();
    }

    // start moon animation immediately, will take 6s to get to the point where it fades in (behind the hero title text)
    animateOrbit('Hero', 200, 200, 8000, 1, 6000);
    fadeInHeroTitlesAsyncWrapper();
}
