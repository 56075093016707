import { useEffect } from 'react';
import { useLocation } from 'react-router-dom'

// hooks

// components

// styles
import styles from './Home.module.css';

// assets
import moonImg from '../../assets/imgs/moon.png';
// import aboutImg from '../../assets/imgs/standOut.png';

// scripts
import animateCharColors from '../../scripts/animateCharColors';
import heroSection from './heroSection';
import animateOrbit from '../../scripts/animateOrbit';

export default function Project() {
  // reset state value so previous data isn't still there when page is refreshed (we want animation to run on page refresh)
  window.history.replaceState({}, '')
  // get state value, will be null unless home page was navigated to by one of the two links in navbar
  let state = useLocation().state

  // runs only on first render
  useEffect(() => {
    if (state === null) {
      // run complete hero animation
      heroSection();
    } else {
      // start just the moon orbit (as all other elements will already be in place)
      animateOrbit('Hero', 200, 200, 8000, 2);
    }
    animateCharColors('animateCharColorsHero', ['REVOLVE', 'DIGITAL', 'Our website is currently undergoing improvements.', 'Please check back soon for an enhanced experience!'], ['h1', 'h1', 'h3', 'h3'], 'Accent1');
  }, []);

  return (
    <div className={styles.home}>
      {/* hero */}
      <div className={styles.hero}>
        <div className={`${styles.heroTitleContainer} animateOrbitCenterHero`}>
          <h1 className={`${styles.fadeInHeroUp} animateCharColorsHero fadeInHeroUp`}></h1>
          <h1 className={`${styles.fadeInHeroUp} animateCharColorsHero fadeInHeroUp`}></h1>
          <h3 className={`${styles.fadeInHeroDown} animateCharColorsHero fadeInHeroDown`}></h3>
          <h3 className={`${styles.fadeInHeroDown} animateCharColorsHero fadeInHeroDown`}></h3>
        </div>

        {/* draggable='false' stops user from being able to click and drag the img */}
        <img className={`${styles.heroImg} animateOrbitSatelliteHero`} src={moonImg} alt='Moon' draggable='false' />
      </div>
    </div>
  );
}
